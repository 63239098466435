import type { Currency } from "@/next-js-app/generated/graphql-code-generator";
import {
  type Translate,
  useTranslations,
} from "@/react-app/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "@/react-app/translations";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { formatPrice } from "@xxl/price-formatting-utils";
import type { DeliveryMethodForProduct } from "../../api/requests";
import { DeliveryCostOrDescription } from "../DeliveryOptions.styled";

const TRANSLATION_KEY_DESCRIPTION_PREFIX = "product.details.delivery.message.";

type DeliveryCostAndDescriptionProps = {
  method: DeliveryMethodForProduct["deliveryMethods"][number];
};

const createPriceMessage = (
  price: number,
  currency: Currency,
  t: Translate
): string => {
  const isFreeShipping = Boolean(price === 0);
  return isFreeShipping
    ? t("product.details.delivery.free")
    : formatPrice(price, currency);
};

export const DeliveryCostAndDescription = ({
  method: { price, description, currency },
}: DeliveryCostAndDescriptionProps) => {
  const { t } = useTranslations();

  return (
    <XxlStack direction={"row"} gap={2}>
      <DeliveryCostOrDescription>
        {createPriceMessage(price, currency, t)}
      </DeliveryCostOrDescription>
      <DeliveryCostOrDescription>
        {description.startsWith(TRANSLATION_KEY_DESCRIPTION_PREFIX)
          ? t(description as TranslationKey)
          : description}
      </DeliveryCostOrDescription>
    </XxlStack>
  );
};
