import type { XXLCookie } from "@/react-app/global";
import { cookieNames } from "@/react-utils/Cookie";
import type { GetServerSidePropsContext } from "next/types";

export const COOKIE_EXPIRATION_IN_SECONDS = 31556926;

export const createCookieString = ({
  cookie,
  domain,
  doubleEncode = false,
  maxAge,
  name,
  path = "/",
  sameSite = "Lax",
}: {
  cookie: unknown;
  maxAge: number | string;
  name: string;
  domain?: string;
  doubleEncode?: boolean;
  path?: string;
  sameSite?: "Strict" | "Lax" | "None";
}) => {
  const cookieString = JSON.stringify(cookie);
  return `${name}=${doubleEncode ? JSON.stringify(cookieString) : cookieString}; Max-Age=${maxAge}; Path=${path}; Secure; SameSite=${sameSite}; ${domain !== undefined ? `Domain=${domain}` : ""}`;
};

export const createXxlCookieForHeader = (
  cookie: XXLCookie,
  req: GetServerSidePropsContext["req"]
) => {
  const { host = "" } = req.headers;
  const domain = host.startsWith("localhost")
    ? host.replace("localhost.", "")
    : host;

  return createCookieString({
    cookie,
    domain,
    maxAge: COOKIE_EXPIRATION_IN_SECONDS,
    name: cookieNames.XXL,
  });
};

export const checkIfIsLoggedIn = (authCookie: string | undefined): boolean =>
  authCookie !== undefined;
