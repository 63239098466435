import { PaymentProviderMessage } from "../PaymentProviderMessage/PaymentProviderMessage";
import { WalleyStaticWidget } from "./WalleyStaticWidget";
import type { PaymentProviderMessageConfig } from "../types";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";

export const WALLEY_WIDGET_VARIANTS = {
  DYNAMIC: "DYNAMIC",
  STATIC: "STATIC",
  DISABLED: "DISABLED",
} as const;

type Props = {
  isGraveyard: boolean;
  walleyWidgetVariant: keyof typeof WALLEY_WIDGET_VARIANTS;
  productSalesPrice: number;
  paymentProviderMessageConfig: PaymentProviderMessageConfig;
  walleyWidgetRef: React.RefObject<HTMLDivElement>;
};
export const PaymentProviderContainer = ({
  isGraveyard,
  productSalesPrice,
  paymentProviderMessageConfig,
  walleyWidgetVariant,
  walleyWidgetRef,
}: Props) => {
  const { t } = useTranslations();
  if (isGraveyard || !paymentProviderMessageConfig.isActive) {
    return null;
  }

  if (walleyWidgetVariant === WALLEY_WIDGET_VARIANTS.STATIC) {
    return <WalleyStaticWidget text={t("pdp.walley.widget.message")} />;
  }
  if (walleyWidgetVariant === WALLEY_WIDGET_VARIANTS.DYNAMIC) {
    return <div ref={walleyWidgetRef} />;
  }
  return (
    <PaymentProviderMessage
      locale={paymentProviderMessageConfig.locale}
      productSalesPrice={productSalesPrice}
    />
  );
};
