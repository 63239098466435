import { getSiteIdentifierByEcomSiteUid, isNotEmpty } from "@xxl/common-utils";
import { Search } from "@xxl/icons";
import * as React from "react";
import { LoadingCircle } from "react-app/src/components/Common";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { ScreenReaderOnly } from "react-app/src/styled/ScreenReaderOnly.styled";
import { removeWhitespace } from "react-app/src/utils/StringTransformations";
import {
  PostalCodeForm,
  PostalCodeInput,
  SubmitButton,
} from "./PostalCodeEntry.styled";
import { useSharedData } from "@/react-app/contexts/SharedData";

type PostalCodeEntryProps = {
  onSubmitPostalCode: (postalCode: string) => void;
  isLoading: boolean;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
};

const validPostalCodeLength = {
  "xxl-no": 4,
  "xxl-se": 5,
  "xxl-fi": 5,
};

export const PostalCodeEntry: React.FunctionComponent<PostalCodeEntryProps> = ({
  onSubmitPostalCode,
  isLoading,
  inputRef = null,
}) => {
  const { t } = useTranslations();
  const {
    data: { siteUid },
  } = useSharedData();
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [isValidPostalCode, setIsValidPostalCode] = React.useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmitPostalCode(postalCode);
  };

  const onPostalCodeChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const numberRegExp = new RegExp(/^\d*$/);
    const input = removeWhitespace(evt.target.value);

    if (!numberRegExp.test(input)) {
      return;
    }

    setPostalCode(input);
    const inputIsValidPostalCode =
      isNotEmpty(input) &&
      input.length ===
        validPostalCodeLength[getSiteIdentifierByEcomSiteUid(siteUid)];
    setIsValidPostalCode(inputIsValidPostalCode);
  };
  return (
    <PostalCodeForm name="postalCodeEntry" onSubmit={handleSubmit}>
      <ScreenReaderOnly>
        <label htmlFor="postalCodeInput">{t("general.postal.code")}</label>
      </ScreenReaderOnly>
      <PostalCodeInput
        data-testid="postalCodeInput"
        name="postalCodeInput"
        id="postalCodeInput"
        type="text"
        inputMode={"numeric"}
        placeholder={t("general.postal.code")}
        disabled={isLoading}
        value={postalCode}
        onChange={onPostalCodeChange}
        maxLength={10}
        ref={inputRef}
      />
      <SubmitButton
        type="submit"
        data-testid="submitButton"
        disabled={!isValidPostalCode}
        aria-label={isLoading ? "loader" : t("general.search")}
        className="button button--square button--primary"
      >
        {isLoading ? <LoadingCircle /> : <Search fontSize={22} />}
      </SubmitButton>
    </PostalCodeForm>
  );
};
