import { QueryClientProvider as QCP, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState, type PropsWithChildren } from "react";

export const QueryClientProvider = ({ children }: PropsWithChildren) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            retry: false,
          },
        },
      })
  );

  return (
    <QCP client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QCP>
  );
};
