import { log } from "@xxl/logging-utils";
import type { SetRequired } from "type-fest";
import { getSessionKey, getXXLCookie } from "../../utils/Cookie";
import type {
  ElevateContext,
  ElevateData,
  ElevateOrderConfirmationTrackingData,
  TrackFavoriteProductProps,
} from "./types";

const getUserKeys = (xxlCookieVersion: string) => {
  const sessionKey = getSessionKey();
  const { customerKey } = getXXLCookie(xxlCookieVersion);

  return {
    customerKey: customerKey ?? crypto.randomUUID(),
    sessionKey: sessionKey,
  };
};

class Elevate {
  constructor(private readonly context: ElevateContext) {}

  sendAddToCartEvent = (
    props: SetRequired<ElevateData, "ticket"> & { xxlCookieVersion: string }
  ) => {
    const {
      elevateApi: {
        storefront: { addToCart },
      },
      siteHost: site,
    } = this.context;
    void addToCart({ ...getUserKeys(props.xxlCookieVersion), site }, props);
  };

  sendFavoritesEvent =
    (eventType: "add" | "remove") =>
    ({ product: productKey, xxlCookieVersion }: TrackFavoriteProductProps) => {
      const {
        elevateApi: { storefront },
        siteHost: site,
      } = this.context;
      void storefront[`${eventType}Favorite`](
        { ...getUserKeys(xxlCookieVersion), site },
        { productKey }
      );
    };

  sendClickEvent = ({
    xxlCookieVersion,
    ticket,
  }: SetRequired<ElevateData, "ticket"> & { xxlCookieVersion: string }) => {
    try {
      const {
        elevateApi: {
          util: { getFullClickUrl },
        },
        siteHost: site,
      } = this.context;
      const { customerKey, sessionKey } = getUserKeys(xxlCookieVersion);
      const url = getFullClickUrl({ customerKey, sessionKey, site });
      const stringifiedData = JSON.stringify({ ticket });
      window.navigator.sendBeacon(url, stringifiedData);
    } catch (error) {
      log.error("Click event not sent.", error);
    }
  };

  sendOrderConfirmationEvent = (
    eventData: ElevateOrderConfirmationTrackingData
  ) => {
    const {
      elevateApi: {
        admin: { paymentNotifications },
      },
      siteHost: site,
    } = this.context;
    void paymentNotifications(
      { ...getUserKeys(eventData.xxlCookieVersion), site },
      eventData,
      {
        validateStatus: () => true,
      }
    );
  };
}

export { Elevate };
