import type { SxProps } from "@mui/material";
import { color } from "@xxl/theme";
import type { SVGProps } from "react";
import { starSize, type StarSize } from "./helpers";
import { StarSvgGroup } from "./styled";

const { gray, amber } = color;

type Props = {
  percentageFilled: number;
  size?: StarSize;
  sx?: SxProps;
} & SVGProps<SVGSVGElement>;

const Star = ({ percentageFilled, size = "md", sx: _sx, ...props }: Props) => {
  if (percentageFilled < 0) {
    throw TypeError(
      `Expected property "percentageFilled" to be >= 0. Actual value: ${percentageFilled};`
    );
  }

  if (percentageFilled > 100) {
    throw TypeError(
      `Expected property "percentageFilled" to be <= 100. Actual value: ${percentageFilled};`
    );
  }

  const filledColor = amber.hex;
  const emptyColor = gray.hex;
  const { width, height } = starSize[size];
  const id = `star-gradient-${percentageFilled}`;

  return (
    <svg width={width} height={height} viewBox="0 0 32 30" {...props}>
      <title>star for ratings summary</title>
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: filledColor, stopOpacity: 1 }}
          />
          <stop
            offset={`${percentageFilled}%`}
            style={{ stopColor: filledColor, stopOpacity: 1 }}
          />
          <stop
            offset={`${percentageFilled}%`}
            style={{ stopColor: emptyColor, stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: emptyColor, stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <StarSvgGroup
        gradientId={id}
        percentageFilled={percentageFilled}
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        unfilledColor={emptyColor}
        {...{ filledColor }}
      >
        <g xmlns="http://www.w3.org/2000/svg" id="surface1">
          <path d="M 15.953125 21.699219 L 24.5 28 L 21.230469 17.828125 L 29.777344 11.667969 L 19.292969 11.667969 L 15.953125 1.167969 L 12.609375 11.667969 L 2.125 11.667969 L 10.675781 17.828125 L 7.402344 28 Z M 15.953125 21.699219 " />
        </g>
      </StarSvgGroup>
    </svg>
  );
};

export { Star };
