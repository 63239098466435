import { PriceId } from "@xxl/product-search-api";
import { useSharedData } from "../../contexts/SharedData";
import {
  getFallbackCustomerKey,
  getSessionKey,
  getXXLCookie,
} from "../../utils/Cookie";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "../../utils/xxl-shared-data";
import { useSession } from "../useSession";
import { useXxlMediaQuery } from "../useXxlMediaQuery";

export const getPriceId = (
  isLoggedIn: boolean,
  isTeamsales: boolean,
  toggle_force_member_price_display: boolean
) =>
  isTeamsales
    ? PriceId.TEAMSALES
    : isLoggedIn || toggle_force_member_price_display
      ? PriceId.MEMBER
      : PriceId.ANONYMOUS;

const useElevateRequestData = () => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const {
    cookieVersion,
    siteUid,
    isTeamsales,
    featureToggles: { toggle_force_member_price_display },
  } = useSharedData().data;

  const getBaseQuery = () => {
    const xxlCookie = getXXLCookie(cookieVersion);

    return {
      customerKey: xxlCookie.customerKey ?? getFallbackCustomerKey(),
      priceId: getPriceId(
        isLoggedIn,
        isTeamsales,
        toggle_force_member_price_display
      ),
      sessionKey: getSessionKey(),
      site: getSiteHost(legacySiteUidToSiteUid(siteUid)),
      touchpoint: isMobile ? ("MOBILE" as const) : ("DESKTOP" as const),
    };
  };

  return {
    getBaseQuery,
  };
};

export { useElevateRequestData };
