import React, { useEffect, type RefObject } from "react";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { setInnerHtml } from "@/react-utils/xxl-set-inner-html";
import type { PaymentWidgetData } from "@/utils/server-side-cache/server-side-cache";
import { isWalley, useCartContext } from "@/react-components/Cart/CartState";
import { WALLEY_WIDGET_VARIANTS } from "../ProductPanel/PaymentProviderContainer";
import { useFeatureToggles } from "@/react-hooks/useFeatureToggle";
import { color } from "@xxl/theme";

const DATA_WIDGET = "part-payment";

const getWalleyVariant = (isWalleyWidget: boolean, isStaticWidget: boolean) => {
  if (isWalleyWidget) {
    if (isStaticWidget) {
      return WALLEY_WIDGET_VARIANTS.STATIC;
    } else {
      return WALLEY_WIDGET_VARIANTS.DYNAMIC;
    }
  }
  return WALLEY_WIDGET_VARIANTS.DISABLED;
};

const createWalleyScript = (
  url: string,
  publicToken: string,
  price: number,
  dw: string,
  dac: string
) => `
  <script
    src="${url}"
    data-token="${publicToken}"
    data-widget=${dw}
    data-amount="${Math.round(price * 100)}"
    data-action-color=${dac}
  />
`;

export const useWalleyWidget = (
  ref: RefObject<HTMLDivElement>,
  pw: PaymentWidgetData | null,
  price: number
) => {
  const { state: cartState } = useCartContext();
  const [updateWalleyWidget, setUpdateWalleyWidget] = React.useState(false);
  const { walley_widget_static_threshold } = useFeatureToggles(
    "walley_widget_static_threshold"
  );

  useEffect(() => {
    if (isNotNullOrUndefined(pw)) {
      const { url, publicToken } = pw;
      if (ref.current !== null) {
        const scriptHTML = createWalleyScript(
          url,
          publicToken,
          price,
          DATA_WIDGET,
          color.green.hex
        );

        setInnerHtml(ref.current, scriptHTML, true);
        setUpdateWalleyWidget(true);
      }
    }

    if (updateWalleyWidget) {
      window.walley?.checkout.api.update();
    }

    return () => {
      setUpdateWalleyWidget(false);
    };
  }, [pw, price, ref, updateWalleyWidget]);

  const isStaticWidget = price < Number(walley_widget_static_threshold);
  const isWalleyWidget = isWalley(cartState.paymentProvider);

  return {
    walleyWidgetVariant: getWalleyVariant(isWalleyWidget, isStaticWidget),
  };
};
