/**
 * Calculate how much of current star that should be filled based on average rating
 * Will be rounded to closest integer or .5
 */
export const getPercentageOfAverageRating = (
  averageRating: number,
  currentStar: number
): number => {
  const roundedAverage = Math.round(averageRating * 2) / 2;
  return Math.min(1, Math.max(0, roundedAverage + 1 - currentStar)) * 100;
};
