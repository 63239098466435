import { useReducer } from "react";
import { getInitialState, reducer, SearchBoxContext } from "./SearchBoxState";
import { SearchInput } from "./SearchInput";
import type { SearchInputProps } from "./types";

export const SearchBox = ({
  hideSuggestions = false,
  query,
}: SearchInputProps) => {
  const [stateStruct, dispatch] = useReducer(reducer, getInitialState());
  const state = stateStruct.current;
  return (
    <SearchBoxContext.Provider value={{ state, dispatch }}>
      <SearchInput hideSuggestions={hideSuggestions} query={query} />
    </SearchBoxContext.Provider>
  );
};
