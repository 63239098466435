import type { StarSize } from "../../common/Star/helpers";
import { Star } from "../../common/Star/Star";
import { StarContainer, StarsContainer } from "../AverageRatingWrapper.styled";
import { getPercentageOfAverageRating } from "./helpers";

type StarsDisplayProps = {
  rating: number;
  size?: StarSize;
  useBottomMargin?: boolean;
  gap?: number;
};

export const StarsDisplay = ({
  rating,
  size = "sm",
  useBottomMargin = false,
  gap,
}: StarsDisplayProps) => {
  return (
    <StarsContainer
      data-testid="rr-average-rating-stars"
      useBottomMargin={useBottomMargin}
    >
      {[1, 2, 3, 4, 5].map((currentStar: number) => (
        <StarContainer key={currentStar} gap={gap}>
          <Star
            percentageFilled={getPercentageOfAverageRating(rating, currentStar)}
            size={size}
          />
        </StarContainer>
      ))}
    </StarsContainer>
  );
};
