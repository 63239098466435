import { isNotNullOrUndefined } from "@xxl/common-utils";
import { CustomWindowEvents } from "react-app/src/components/Common/util";
import { getPriceString } from "../../components/CrossSales/utils";
import type {
  ElevateOrderConfirmationTrackingData,
  ProductData,
  ProductTrackingData,
} from "./types";

export const convertProduct = ({
  name,
  code,
  brand,
  categoryName,
  googleCategory,
  priceDisplay,
  pk,
}: ProductData): ProductTrackingData => ({
  name: name ?? "",
  id: code,
  brand: brand?.name,
  category: googleCategory ?? categoryName,
  price: isNotNullOrUndefined(priceDisplay) ? getPriceString(priceDisplay) : "",
  priceType: priceDisplay?.type,
  pk,
});

export const trackWhenConsentGiven = (onConsent: () => void): (() => void) => {
  window.addEventListener(
    CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
    onConsent
  );
  return () =>
    window.removeEventListener(
      CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
      onConsent
    );
};

export const mapOrderConfirmationTrackingDataForElevate = (
  productData: {
    ean: string;
    quantity: number;
  }[],
  xxlCookieVersion: string
): ElevateOrderConfirmationTrackingData => {
  return {
    lines: productData.map(({ ean, quantity }) => ({
      quantity,
      variantKey: ean,
    })),
    xxlCookieVersion,
  };
};
