// left in react-app due to usage in StoreStockStyles
export default {
  header: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  changeButton: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  stockList: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  allStoresButton: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    textDecoration: "underline",
    letterSpacing: -0.15,
  },
};
