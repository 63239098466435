import * as React from "react";
import * as yup from "yup";
import type { FieldProps, FormikProps, FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import { FormErrorMessage, Input, Label } from "../../styled";
import {
  ButtonWrapper,
  CardAmount,
  CardBalance,
  CardExpDate,
  CardExpDescription,
  CardLogo,
  CardName,
  DescriptionContentWrapper,
  FieldSet,
  GiftCard,
  StyledButton,
} from "./GiftCardBalanceCheckForm.styled";
import { useState } from "react";
import type { GiftCardBalanceData } from "@xxl/frontend-api";
import { GiftcardApi, Configuration } from "@xxl/frontend-api";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useSharedData } from "../../contexts/SharedData";

export const giftCardPinRE = /\b\d{4}\b/;
export const giftCardNumberRE = /\b\d{19}\b/;

const formatDate = (date: string): string => {
  return new Date(date).toISOString().split("T")[0];
};

type GiftCardBalanceCheckForm = {
  title?: string;
  description?: string;
};

type GiftCardBalanceCheck = {
  cardNumber: string;
  cardPin: string;
};

const GiftCardBalanceCheckForm: React.FunctionComponent<
  GiftCardBalanceCheckForm
> = ({ title = "", description = "" }) => {
  const [giftCardData, setGiftCardData] = useState<GiftCardBalanceData>();
  const { configuration, siteCurrency } = useSharedData().data;
  const [giftCardApi] = React.useState(
    new GiftcardApi(new Configuration(configuration.frontendApi))
  );
  const { t } = useTranslations();
  const giftCardBalanceCheckSchema = yup.object({
    cardNumber: yup
      .string()
      .matches(
        giftCardNumberRE,
        t("giftcard.balance.check.form.cardnumber.invalid")
      )
      .required(t("giftcard.balance.check.form.cardnumber.empty")),
    cardPin: yup
      .string()
      .matches(giftCardPinRE, t("giftcard.balance.check.form.cardpin.invalid"))
      .required(t("giftcard.balance.check.form.cardpin.empty")),
  });

  const handleSubmit = async (
    { cardNumber, cardPin }: GiftCardBalanceCheck,
    { setSubmitting, setFieldError }: FormikHelpers<GiftCardBalanceCheck>
  ): Promise<void> => {
    try {
      const response =
        await giftCardApi.giftCardControllerGetGiftCardBalancePOST({
          cardNumber: cardNumber.trim(),
          cardPin: cardPin.trim(),
        });

      setGiftCardData(response.data);
      if (response.data.isActive === false) {
        setFieldError("cardNumber", t("giftcard.balance.check.card.expired"));
      }
    } catch (error) {
      setFieldError(
        "cardNumber",
        t("giftcard.balance.check.form.cardnumber.notfound")
      );
      setGiftCardData({});
    }

    setSubmitting(false);
  };

  const hasTitleOrDescription = Boolean(description || title);
  return (
    <>
      {hasTitleOrDescription && (
        <DescriptionContentWrapper>
          {title && <h2>{title}</h2>}
          {description && <p>{description}</p>}
        </DescriptionContentWrapper>
      )}

      <Formik
        initialValues={{ cardNumber: "", cardPin: "" }}
        validationSchema={giftCardBalanceCheckSchema}
        onSubmit={handleSubmit}
        key="giftcard-balance-check-form"
      >
        {({ isSubmitting }: FormikProps<GiftCardBalanceCheck>): JSX.Element => (
          <Form data-testid="gift-card-balance-check-form">
            <FieldSet disabled={isSubmitting}>
              <Label data-testid="card-number">
                {t("giftcard.balance.check.form.cardnumber.label")}
                <Field name="cardNumber">
                  {
                    (({ field, meta }) => (
                      <>
                        <Input
                          {...field}
                          placeholder={t(
                            "giftcard.balance.check.form.cardnumber.placeholder"
                          )}
                        />
                        <FormErrorMessage>
                          {meta.touched && meta.error !== undefined
                            ? meta.error
                            : null}
                        </FormErrorMessage>
                      </>
                    )) as (props: FieldProps) => React.ReactNode
                  }
                </Field>
              </Label>
              <Label data-testid="card-pin">
                {t("giftcard.balance.check.form.cardpin.label")}
                <Field name="cardPin">
                  {
                    (({ field, meta }) => (
                      <>
                        <Input
                          {...field}
                          placeholder={t(
                            "giftcard.balance.check.form.cardpin.placeholder"
                          )}
                        />
                        <FormErrorMessage>
                          {meta.touched && meta.error !== undefined
                            ? meta.error
                            : null}
                        </FormErrorMessage>
                      </>
                    )) as (props: FieldProps) => React.ReactNode
                  }
                </Field>
              </Label>
              <ButtonWrapper>
                <StyledButton
                  id="gift-card-balance-check-form-button"
                  type="submit"
                  className="button button--primary button--small"
                >
                  {t("giftcard.balance.check.form.cardnumber.submit")}
                </StyledButton>
              </ButtonWrapper>
            </FieldSet>
          </Form>
        )}
      </Formik>
      {giftCardData !== undefined && giftCardData.validTo !== undefined && (
        <GiftCard data-testid="card-data">
          <CardName>{t("giftcard.balance.check.card.name")}</CardName>
          <CardLogo>XXL</CardLogo>
          <CardBalance>
            {t("giftcard.balance.check.card.balance.label")}
            <CardAmount data-testid="card-amount">
              {giftCardData.balance} {siteCurrency}
            </CardAmount>
          </CardBalance>
          <CardExpDate data-testid="card-expiration">
            <CardExpDescription>
              {t("giftcard.balance.check.card.exp")}
            </CardExpDescription>
            {formatDate(giftCardData.validTo)}
          </CardExpDate>
        </GiftCard>
      )}
    </>
  );
};

export { GiftCardBalanceCheckForm };
