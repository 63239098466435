import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { fadeInAnimation } from "../../../styled/mixins";
import { ButtonStyledAsLink } from "../../../styled";
import { typographyToCss } from "../../../styles/helpers";
import text from "../RatingAndReview.typography";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";

const ratingTextColor = colors.xxlWebBlack;

type StarContainerProps = {
  gap?: number;
};

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  ${fadeInAnimation("averageRatingAppear", "50ms")};
  flex-direction: column;
  flex-wrap: wrap;
`;

export const AverageText = styled.div`
  color: ${ratingTextColor};
`;

export const Button = styled(ButtonStyledAsLink)`
  ${typographyToCss(text.averageRating.reviewsNumber)};
  display: flex;
  align-items: center;
  color: ${ratingTextColor};
`;

export const StarContainer = styled.div<StarContainerProps>`
  display: flex;
  margin-right: ${(props): string =>
    props.gap !== undefined ? `${props.gap}px` : `6px`};
`;

type StarsContainerProps = {
  useBottomMargin?: boolean;
};

export const StarsContainer = styled.div<StarsContainerProps>(
  ({ useBottomMargin }) => css`
    display: flex;
    align-items: center;
    ${fadeInAnimation("averageRatingAppear", "50ms")};
    margin-bottom: ${useBottomMargin === true ? "8px" : 0};
  `
);
