export const PERSONALIZED_PRODUCT_LIST_QUERY_NAME = "gtmProductListName";
export const PRODUCT_VARIANT_THUMB_SIZE = 60;
export const DEFAULT_GRID_VALUE_DESKTOP = 6;
export const DEFAULT_GRID_VALUE_MOBILE = 2 as const;

export const SLIDER_OPTIONS = {
  centered: true,
  slidesPerView: 1,
  mode: "snap",
  spacing: 12,
  dragSpeed: 0.5,
} as const;
