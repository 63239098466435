import { type CategoryData } from "@xxl/pim-api";

export const getCategoryData = (
  categoryCode: string | undefined,
  allCategories: CategoryData[]
): CategoryData | undefined => {
  if (categoryCode === undefined || allCategories.length === 0) {
    return;
  }

  const categoryData = allCategories.find((item) => item.code === categoryCode);

  if (categoryData === undefined) {
    return;
  }

  const { breadcrumbs, categoryLevel, pageTitle, url } = categoryData;

  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ name, url }) => ({ name, url }));

  return {
    code: categoryCode,
    ...(url !== undefined && { url }),
    ...(categoryLevel !== undefined && { categoryLevel }),
    subCategories: subCategories,
    categoryLevel,
    ...(pageTitle !== undefined && { pageTitle }),
    ...(breadcrumbs !== undefined && { breadcrumbs }),
  };
};
