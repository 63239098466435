import { isEmpty, isNotEmpty, isNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { Buffer } from "buffer";
import Cookies from "js-cookie";
import type { AVAILABILITY } from "../../components/Search/SearchFetchProductsHelper.types";
import type { CartDetailsCookie, GiosgProduct, XXLCookie } from "../../global";
import { windowAccess } from "../Window";
import { xxlCookieInit } from "./XXLCookieInit";
import type {
  ConsentCookie,
  CookieName,
  PreferredStoreIds,
  XXLSessionCookie,
} from "./types";
import { cookieCategories, cookieNames } from "./types";

xxlCookieInit();

type CartCookie = (GiosgProduct & { productCode: string })[];
type PreferredStores = {
  ids: PreferredStoreIds;
  availability: AVAILABILITY[];
};
const MAX_RECENTLY_VIEWED_PRODUCTS = 24;
const DAYS_IN_A_YEAR = 365;

const getCookieRaw = (name: CookieName): string | null =>
  Cookies.get(name) ?? null;

const parseCookie = <T>(
  cookieValue: string | undefined,
  cookieName: string
): T | null => {
  try {
    if (isNullOrUndefined(cookieValue) || isEmpty(cookieValue)) {
      log.info("Cookie name, value:", cookieName, cookieValue);
      return null;
    }
    return JSON.parse(cookieValue.replace(/\\/g, "")) as T;
  } catch (error) {
    log.error(
      "Could not parse cookie name, value, error:",
      cookieName,
      cookieValue,
      error
    );
  }
  return null;
};

const getCookieParsed = <T>(name: CookieName): T | null => {
  const cookie = Cookies.get(name) ?? null;
  if (cookie === null) {
    return null;
  }

  return parseCookie<T>(cookie, name);
};

const getFallbackKey = () => crypto.randomUUID();
const getFallbackCustomerKey = getFallbackKey;
const getFallbackSessionKey = getFallbackKey;

/**
 * Elevate customer key from XXL cookie.
 * @returns string | null
 */
const getCustomerKey = (): string | null => {
  const xxlCookie = getCookieParsed<XXLCookie>(cookieNames.XXL);
  return xxlCookie?.customerKey ?? null;
};

/**
 * Elevate session key from sessionKey cookie. Creates and sets sessionKey if it doesn't exist.
 * @returns string
 */
const getSessionKey = (): string => {
  const cookie = getCookieRaw(cookieNames.SESSION_KEY);

  if (typeof cookie !== "string") {
    const createdSessionKey = getFallbackSessionKey();
    Cookies.set(cookieNames.SESSION_KEY, createdSessionKey);
    return createdSessionKey;
  }

  return cookie;
};

/**
 * Use to create new XXL cookie.
 * @param cookieVersion ex "v5"
 * @param customerKey uuid ex "501ac56b-1320-4611-b83a-e43d6a987730"
 * @returns
 */
export const createXxlCookie = (
  cookieVersion: string,
  customerKey?: string
) => {
  const now = Date.now();
  const cookie = {
    cookieVersion,
    customerKey: customerKey ?? getFallbackCustomerKey(),
    lastModified: now,
    loggedIn: false,
    teamSalesData: { clubUIDs: [] },
    totalItems: 0,
    sessionId: getFallbackSessionKey(),
  };

  return cookie;
};

/**
 * Get the cookie domain for the current env
 *
 * @param host not needed server-side
 * @returns cookie domain or fallback to empty string
 */
export const getCookieDomain = (host?: string): string => {
  const domain =
    typeof process !== "undefined" ? process.env.ENV_DOMAIN_NAME ?? host : host;
  if (isEmpty(domain)) {
    return "";
  }
  return ".".concat(domain.split(".").slice(1).join("."));
};

const serializeCookieFromBase64 = (cookieString: string) =>
  Buffer.from(cookieString, "base64").toString("binary");

const getBase64EncodedSerializedCookie = <T>(name: CookieName): T | null => {
  const unparsedCookie = Cookies.get(name) ?? null;

  if (unparsedCookie === null) {
    return null;
  }

  const serializedCookie = serializeCookieFromBase64(unparsedCookie);
  const cookie = parseCookie<T>(serializedCookie, name);

  if (cookie === null) {
    throw Error(`Could not parse ${name} cookie.`);
  }

  return cookie;
};

const setCookie = (name: CookieName, value: string): string | undefined =>
  Cookies.set(name, value, { expires: DAYS_IN_A_YEAR });

const getUserGroups = (xxlCookieObject: XXLCookie): string =>
  xxlCookieObject.userGroups ?? "";

const getUserId = (xxlCookieObject: XXLCookie): string =>
  xxlCookieObject.loopId ?? "";

function isCookieValid(xxlCookie: XXLCookie, cookieVersion?: string) {
  return (
    xxlCookie.cookieVersion ===
    (cookieVersion ?? windowAccess()._sharedData.cookieVersion)
  );
}

const getDomain = (hostname: string): string => {
  const parts = hostname.split(".");

  // Ex xxl.se
  if (parts.length <= 2) {
    return `.${hostname}`;
  }

  return `.${parts.slice(-4).join(".")}`;
};

/**
 * A deploy on 2025-04-04 accidentally introduced cookies with the wrong domain, try to remove them
 * cleanup in https://xxlsports.atlassian.net/browse/XD-17770
 */
const clearInvalidCookieIfPresent = () => {
  if (typeof window === "undefined") {
    return;
  }
  Cookies.remove(cookieNames.XXL, {
    path: "/",
    domain: `.${window.location.hostname}`,
    secure: true,
    sameSite: "Lax",
  });
};

const getXXLCookie = (cookieVersion: string): XXLCookie => {
  clearInvalidCookieIfPresent();
  const existingCookie = getCookieParsed<XXLCookie>(cookieNames.XXL);
  if (existingCookie !== null && isCookieValid(existingCookie, cookieVersion)) {
    return existingCookie;
  }

  const domain = getCookieDomain(
    typeof window !== "undefined" ? window.location.hostname : undefined
  );
  const newCookie = createXxlCookie(cookieVersion);
  if (typeof document !== "undefined" && isNotEmpty(domain)) {
    // Cookies.set from "js-cookie"; escapes out our invalid json cookie data, so do this manually instead
    document.cookie =
      "xxl=" +
      JSON.stringify(JSON.stringify(newCookie)) +
      `; expires=${new Date(Date.now() + 31556926e3).toUTCString()}; path=/; domain=${domain}; sameSite=Lax; secure;`;
  }
  return newCookie;
};

const getXXLSessionCookie = (): XXLSessionCookie => {
  const cookie = getCookieRaw(cookieNames.XXL_SESSION);
  return cookie !== null
    ? (JSON.parse(cookie) as XXLSessionCookie)
    : {
        recentlyViewedProducts: [],
      };
};

const getCookieConsentValue = (cookieCategory: string): boolean =>
  window.CookieInformation?.getConsentGivenFor(cookieCategory) ?? false;

const isMarketingCookieEnabled = (): boolean =>
  getCookieConsentValue(cookieCategories.MARKETING);

const addRecentlyViewedProduct = (productId: string): void => {
  if (!isMarketingCookieEnabled()) {
    return;
  }

  const cookie = getXXLSessionCookie();
  if (cookie.recentlyViewedProducts === undefined) {
    cookie.recentlyViewedProducts = [];
  }

  const index = cookie.recentlyViewedProducts.indexOf(productId);
  if (index === -1) {
    cookie.recentlyViewedProducts.unshift(productId);
  } else {
    cookie.recentlyViewedProducts.splice(index, 1);
    cookie.recentlyViewedProducts.unshift(productId);
  }
  if (cookie.recentlyViewedProducts.length > MAX_RECENTLY_VIEWED_PRODUCTS) {
    cookie.recentlyViewedProducts.splice(
      MAX_RECENTLY_VIEWED_PRODUCTS,
      cookie.recentlyViewedProducts.length - MAX_RECENTLY_VIEWED_PRODUCTS
    );
  }
  setCookie(cookieNames.XXL_SESSION, JSON.stringify(cookie));
};

const getCookieInformationConsent = (): ConsentCookie | null =>
  getCookieParsed(cookieNames.COOKIE_INFORMATION_CONSENT);

const getPreferredStoresCookie = (): PreferredStores | null =>
  getCookieParsed(cookieNames.PREFERRED_STORES);

const getCartCookie = (): CartCookie | null =>
  getCookieParsed(cookieNames.CART);

const getEventStreamSessionCookie = (): string | null =>
  getCookieRaw(cookieNames.EVENTSTREAM_SESSION);

const isFunctionalCookieEnabled = (): boolean =>
  getCookieConsentValue(cookieCategories.FUNCTIONAL);

const setPreferredStoresCookie = (preferredStores: PreferredStores): void => {
  if (isFunctionalCookieEnabled()) {
    setCookie(cookieNames.PREFERRED_STORES, JSON.stringify(preferredStores));
  }
};

const getCartDetailsCookie = (): CartDetailsCookie | null =>
  getBase64EncodedSerializedCookie<CartDetailsCookie>(cookieNames.CART_DETAILS);

const getMemberNumber = (): string | null =>
  getCookieParsed<string | null>(cookieNames.MEMBER_NUMBER) ?? null;

const mockedConsentCookie = {
  // for unit testing purposes, move to another file if more appropriate
  consents_approved: Object.values<string>(cookieCategories),
  consents_denied: [],
};

export {
  addRecentlyViewedProduct,
  getBase64EncodedSerializedCookie,
  getCartCookie,
  getCartDetailsCookie,
  getCookieInformationConsent,
  getCookieParsed,
  getCookieRaw,
  getCustomerKey,
  getDomain,
  getEventStreamSessionCookie,
  getFallbackCustomerKey,
  getFallbackSessionKey,
  getMemberNumber,
  getPreferredStoresCookie,
  getSessionKey,
  getUserGroups,
  getUserId,
  getXXLCookie,
  getXXLSessionCookie,
  isFunctionalCookieEnabled,
  isMarketingCookieEnabled,
  mockedConsentCookie,
  parseCookie,
  serializeCookieFromBase64,
  setCookie,
  setPreferredStoresCookie,
};

export type { PreferredStores };
