type StarSvgGroupProps = {
  gradientId: string;
  percentageFilled: number;
  unfilledColor: string;
  filledColor: string;
};

/**
 * Returns gradient url when percentage is between 0 and 100,
 * otherwise color to enable transitions
 */
export const getFillValue = (props: StarSvgGroupProps): string => {
  switch (props.percentageFilled) {
    case 0:
      return props.unfilledColor;
    case 100:
      return props.filledColor;
    default:
      return `url(#${props.gradientId})`;
  }
};

export const starSize = {
  xxs: {
    width: "8px",
    height: "8px",
  },
  xs: {
    width: "12px",
    height: "12px",
  },
  sm: {
    width: "17px",
    height: "16px",
  },
  md: {
    width: "32px",
    height: "30px",
  },
} as const;

export type StarSize = keyof typeof starSize;
