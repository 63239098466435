import { isNotEmpty } from "@xxl/common-utils";
import * as React from "react";
import type { DeliveryMethodForProduct } from "../api/requests";
import {
  DeliveryName,
  DetailsWrapper,
  Image,
  Location,
  LocationAndPrice,
} from "./DeliveryOptions.styled";
import { DeliveryCostAndDescription } from "./DeliveryCostAndDescription";

type DeliveryMethodsProps = {
  method: DeliveryMethodForProduct["deliveryMethods"][number];
};

export const DeliveryMethod: React.FunctionComponent<DeliveryMethodsProps> = ({
  method,
}) => {
  return (
    <DetailsWrapper isNew={false} data-testid="deliveryOption">
      <LocationAndPrice>
        <DeliveryName>
          <Location>{method.name}</Location>
        </DeliveryName>
        <DeliveryCostAndDescription method={method} />
      </LocationAndPrice>
      {isNotEmpty(method.imageUrl) && (
        <Image
          data-private={true}
          src={method.imageUrl}
          alt={`${method.name} logo`}
          width={24}
          height={12}
        />
      )}
    </DetailsWrapper>
  );
};
