import type { TranslationKey } from "../../../translations";
import type * as api from "../Api/RatingsAndReviewsAPI";

const getFormattedDate = (
  timestamp: number,
  locales?: string[] | string
): string => {
  const fallbackLocales =
    (typeof locales === "undefined" && "Cypress" in window) ||
    (typeof locales === "undefined" && "jest" in window)
      ? "en-US"
      : undefined;
  const date = new Date(timestamp);
  return date.toLocaleDateString(locales ?? fallbackLocales);
};

const languageCodeToTranslationKeyMap: {
  [index: string]: TranslationKey;
} = {
  de: "product.ratings-and-reviews.translated.from.german",
  da: "product.ratings-and-reviews.translated.from.danish",
  fi: "product.ratings-and-reviews.translated.from.finnish",
  no: "product.ratings-and-reviews.translated.from.norwegian",
  sv: "product.ratings-and-reviews.translated.from.swedish",
};
const supportedLanguages = Object.keys(languageCodeToTranslationKeyMap);

function findTranslationByLanguage(
  review: api.Review,
  userLanguage: string,
  fallbackLanguage: string
): api.ReviewTranslationData | null {
  const { translations } = review;
  const sourceLanguage = translations[0]?.sourceLanguage;

  if (sourceLanguage === userLanguage) {
    return null;
  }

  const getTranslation = (lang: string): api.ReviewTranslationData | null =>
    translations.find((t) => t.language === lang) ?? null;

  return getTranslation(userLanguage) ?? getTranslation(fallbackLanguage);
}

export {
  findTranslationByLanguage,
  getFormattedDate,
  languageCodeToTranslationKeyMap,
  supportedLanguages,
};
