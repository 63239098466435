import { useSharedData } from "@/react-app/contexts/SharedData";
import spaces from "@/react-app/styles/theme/spaces";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { DeliveryTruck } from "@xxl/icons";
import {
  SanitizedHtmlComponent,
  SkeletonWrapper,
} from "react-app/src/components/Common";
import { DeliveryMessage } from "../DeliveryInfo.styled";

type MainMessageProps = {
  messageSetDangerously: string | null;
  isLoading?: boolean;
};

const MainMessage = ({
  isLoading = false,
  messageSetDangerously,
}: MainMessageProps) => {
  const {
    featureToggles: { toggle_checkout_delivery_options },
  } = useSharedData().data;

  return (
    <XxlStack
      direction={toggle_checkout_delivery_options ? "row" : "column"}
      gap={toggle_checkout_delivery_options ? 1 : undefined}
    >
      <DeliveryTruck
        fontSize={24}
        style={
          toggle_checkout_delivery_options
            ? { marginTop: spaces.micro }
            : undefined
        }
      />
      {messageSetDangerously !== null && (
        <SkeletonWrapper isLoading={isLoading}>
          <DeliveryMessage data-testid="deliveryMessage">
            <SanitizedHtmlComponent text={messageSetDangerously} />
          </DeliveryMessage>
        </SkeletonWrapper>
      )}
    </XxlStack>
  );
};

export { MainMessage };
