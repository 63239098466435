import type {
  DeliveryMethodForProductQuery,
  ProductsDeliveryMethodsInput,
} from "@/next-js-app/generated/graphql-code-generator";
import type { AmplifyConfig } from "@/next-js-app/global";
import type { EcomSiteUidLegacy } from "@/react-app/global";
import { hasValue } from "@xxl/common-utils";
import {
  Configuration,
  type ConfigurationParameters,
  type DeliveryOptionsResponse,
  ShipmentsApi,
} from "@xxl/shipments-api";
import { callGraphQL } from "react-app/src/graphql/graphqlApi";

export type DeliveryMethodForProduct = NonNullable<
  DeliveryMethodForProductQuery["fetchDeliveryMethodForProducts"]
>;

const deliveryMethodForProductQuery = /* GraphQL */ `
  query DeliveryMethodForProduct($input: ProductsDeliveryMethodsInput!) {
    fetchDeliveryMethodForProducts(input: $input) {
      onOrderItem
      expressDeliveryAvailable
      deliveryMethods {
        currency
        description
        imageUrl
        name
        price
        type
      }
    }
  }
`;

export const getApiClient = (
  shipmentsApiConfig: ConfigurationParameters,
  siteUid: EcomSiteUidLegacy,
  toggle_checkout_delivery_options: boolean,
  amplifyConfig: AmplifyConfig
) => {
  if (toggle_checkout_delivery_options) {
    return {
      getDeliveryOptions: async (
        postalCode: string,
        productCode: string
      ): Promise<DeliveryMethodForProduct> => {
        const input: ProductsDeliveryMethodsInput = {
          products: [{ code: productCode, quantity: 1 }],
          postalCode,
          filterPickUpAtStore: false,
        };
        const { data } = await callGraphQL<DeliveryMethodForProductQuery>(
          {
            query: deliveryMethodForProductQuery,
            variables: { input },
            forceAnonymous: true,
          },
          amplifyConfig.aws_appsync_graphqlEndpoint,
          amplifyConfig.aws_appsync_apiKey
        );
        if (hasValue(data?.fetchDeliveryMethodForProducts)) {
          return data.fetchDeliveryMethodForProducts;
        } else {
          throw Error("fetchDeliveryMethodForProducts failed");
        }
      },
    };
  } else {
    const shipmentsApi = new ShipmentsApi(
      new Configuration(shipmentsApiConfig)
    );
    return {
      getDeliveryOptions: async (
        postalCode: string,
        productCode: string
      ): Promise<DeliveryOptionsResponse> => {
        const { data } = await shipmentsApi.getDeliveryOptions(
          siteUid,
          postalCode,
          productCode
        );
        return data;
      },
    };
  }
};
