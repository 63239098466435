import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import React from "react";
import stringFormat from "string-format";
import { FormButton } from "./FormButton";
import { Message } from "../Message";
import { Container } from "./styles";
import { getRestockDate } from "./utils";

/**
 * ProductFormArchived component displays information for an archived/unavailable.
 * It shows a form button and a message indicating when the product will be restocked.
 */
export const ProductFormArchived = ({
  btnRef,
  productName,
}: {
  btnRef: React.MutableRefObject<null>;
  isRefInView: boolean;
  productName: string;
}) => {
  const { t } = useTranslations();

  const text = stringFormat(
    t("product.details.unavailable.product"),
    productName,
    getRestockDate()
  );

  return (
    <>
      <Container ref={btnRef}>
        <FormButton />
        <Message text={text} />
      </Container>
    </>
  );
};
