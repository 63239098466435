import { Apis } from "@/utils/api-helper";
import { getCategoryData } from "@/utils/apis/category-data";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { hasNoValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  BaseCampaignData,
  BrandData,
  CategoryLevelOneLink,
  CategoryLevelTwoLink,
  Image,
  MegaMenuContentData,
} from "@xxl/content-api";
import type {
  BaseBrandData,
  BaseCampaignData as CampaignData,
  ImageData,
  MegaMenuContentData as MegaMenuContent,
  MegaMenuLevelOneLink,
  MegaMenuLevelTwoLink,
} from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import { type CategoryData } from "@xxl/pim-api";
import { isDateWithinRange } from "../date-helper";
import {
  getEnvVar,
  getLegacySiteUid,
  getSiteUid,
} from "../environment-variables";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";

const mapImage = (image: Image | undefined): ImageData | undefined =>
  image?.url !== undefined
    ? {
        ...{ ...image },
        baseUrl: image.url,
      }
    : undefined;

const mapCampaign = (
  content: Image | undefined,
  campaigns: BaseCampaignData[] | undefined
): CampaignData | undefined => {
  const campaign =
    content === undefined && isNotNullOrUndefined(campaigns)
      ? campaigns.find(isDateWithinRange)
      : undefined;

  if (campaign === undefined) {
    return;
  }

  const { bannerImage, slug } = campaign;

  return {
    ...{
      ...campaign,
    },
    ...(isNotNullOrUndefined(bannerImage) && {
      bannerImage: mapImage(bannerImage),
    }),
    ...(isNotNullOrUndefined(slug) && {
      url: `${getEnvVar(
        "REQUEST_MAPPING_CAMPAIGNHUBPAGE"
      )}/${encodeURIComponent(slug)}`,
    }),
  };
};

const mapLink = (
  link: CategoryLevelTwoLink,
  categoryLinks: CategoryData[]
): MegaMenuLevelTwoLink => {
  const category = getCategoryData(link.categoryCode, categoryLinks);
  const content = mapImage(link.content);

  return {
    displayName: link.displayName,
    ...(category !== undefined && { category }),
    ...(content !== undefined && { content }),
  };
};

const mapBrandLinks = (brandLinks: BrandData[]): BaseBrandData[] => {
  return brandLinks.map(({ brandCode, brandName }) => ({
    brandCode,
    brandName,
    url: `/b/${brandCode ?? ""}/${brandName ?? ""}`,
  }));
};

const mapLinks = async (
  rawLinks: CategoryLevelOneLink[]
): Promise<MegaMenuLevelOneLink[]> => {
  const pageUid = getLegacySiteUid();
  try {
    const categoriesString = rawLinks
      .map((link) => link.categoryCode)
      .join(",");
    const subCategoriesString = rawLinks
      .map((link) => link.links?.map((item) => item.categoryCode).join(","))
      .join(",");
    const [pimResponse] = await Promise.all([
      Apis.getInstance().pimApi.getCategories(
        pageUid,
        categoriesString + "," + subCategoriesString
      ),
    ]);

    const pimCategoriesResponse = pimResponse.data.filter(
      (link) => link.code !== undefined
    );

    const links = Promise.all(
      rawLinks.map((link): MegaMenuLevelOneLink => {
        const campaign = mapCampaign(link.content, link.campaigns);

        return {
          ...mapLink(link, pimCategoriesResponse),
          links:
            link.links?.map((item) => mapLink(item, pimCategoriesResponse)) ??
            [],
          ...(campaign !== undefined && { campaign }),
          brandLinks: mapBrandLinks(link.brandLinks ?? []),
          ...(link.buttonText !== undefined && { buttonText: link.buttonText }),
          ...(link.contentText !== undefined && {
            contentText: link.contentText,
          }),
          ...(link.link !== undefined && { link: link.link }),
        };
      })
    );

    return links;
  } catch (error) {
    const message =
      (error as { message?: string }).message ?? "error message undefined";
    log.error(
      "Error fetching mega menu links, to see more data turn on debug mode",
      message
    );
    log.debug("Error fetching mega menu links", error);
  }
  return [];
};

const mapToContent = async (
  menuContent: MegaMenuContentData
): Promise<MegaMenuContent> => {
  const content = mapImage(menuContent.content);

  return {
    campaignHubLink: menuContent.campaignHubLink,
    links: await mapLinks(menuContent.categoryLinks ?? []),
    ...(content !== undefined && { content }),
  };
};

/**
 * Server-side only fetching of mega menu content
 */
const fetchMegaMenuInternal = async () => {
  const {
    data: { result: [menuContent] = [] },
  } = await Apis.getInstance().contentApi.getMegaMenu(getSiteUid());
  if (hasNoValue(menuContent)) {
    return;
  }
  return await mapToContent(menuContent);
};

const fetchMegaMenu = memoize(
  fetchMegaMenuInternal,
  new Keyv({ namespace: KeyvCacheNamespace.MEGA_MENU_CONTENT }),
  KeyvDefaultTtl
);

export { fetchMegaMenu };
export type { MegaMenuContent };
