const cacheControl = "Cache-Control";
const FIVE_MINUTES_IN_SECONDS = 5 * 60;
export const CommonCacheControlHeader = `public, s-maxage=${FIVE_MINUTES_IN_SECONDS}, max-age=${FIVE_MINUTES_IN_SECONDS}, stale-while-revalidate=${FIVE_MINUTES_IN_SECONDS}`;
export const CommonCacheControl = [
  cacheControl,
  CommonCacheControlHeader,
] as const;
export const PrivateCacheControl = [
  cacheControl,
  `private, max-age=${FIVE_MINUTES_IN_SECONDS}`,
] as const;
const ONE_HOUR_IN_SECONDS = 60 * 60;
export const LongCacheControl = [
  cacheControl,
  `public, s-maxage=${ONE_HOUR_IN_SECONDS}, max-age=${ONE_HOUR_IN_SECONDS}, stale-while-revalidate=${ONE_HOUR_IN_SECONDS}`,
] as const;

export const markets = ["no", "fi", "se"] as const;
export const XXL_CART_DETAILS_COOKIE_NAME = "cartDetails";
export const XXL_TOGGLE_COOKIE_NAME = "xxl-toggle";
export const FITSTATION_ENABLED_CATEGORY_CODES = new Set([
  "142000",
  "140200",
  "140400",
  "140600",
]);

/* variables used in page title rendering */
export const COMPANY_NAME_KEY = "meta.title.default";
export const DEFAULT_COMPANY_NAME = "XXL";
export const SYMPLIFY_ORIGINAL_KEY = "Original";

export const NotFoundResponse = { notFound: true } as const;

export const STYLE_ID_FIRST_DIGITS_LENGTH = 7;
