import React from "react";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { ButtonStyledAsLink } from "react-app/src/styled";
import { Header, PostalCodeWrapper, Wrapper } from "./HeaderSection.styled";
import { Text } from "react-app/src/components/Text";
import { Icon } from "react-app/src/components/Icon";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const {
  colors: { xxlLightGreen },
} = xxlTheme;

type HeaderSectionNewProps = {
  onClickEnterNewPostalCode: () => void;
  postalCode: string | null;
};

export const HeaderSection = ({
  onClickEnterNewPostalCode,
  postalCode,
}: HeaderSectionNewProps) => {
  const { t } = useTranslations();

  const hasPostalCode = postalCode !== null;

  return (
    <Wrapper bgcolor={xxlLightGreen} direction="row">
      <Header>
        {t(
          hasPostalCode
            ? "product.details.delivery.options"
            : "product.details.delivery.check.express"
        )}
      </Header>
      <PostalCodeWrapper>
        <Icon name="LocationPin" />
        {hasPostalCode && <Text fontFamily="bold">{postalCode}</Text>}
        <ButtonStyledAsLink
          onClick={onClickEnterNewPostalCode}
          data-testid={"addPostalCode"}
        >
          {t(
            hasPostalCode
              ? "product.details.delivery.change.postal.code"
              : "product.details.delivery.add.postal.code"
          )}
        </ButtonStyledAsLink>
      </PostalCodeWrapper>
    </Wrapper>
  );
};
