import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { color } from "@xxl/theme";

export const WalleyStaticWidget = ({ text }: { text: string }) => {
  return (
    <XxlStack
      p={xxlTheme.spaces.mini}
      border={`1px solid ${color.lightGray.cssVariable}`}
      gap={xxlTheme.spaces.small}
    >
      {text}
      <XxlStack height={xxlTheme.spaces.smallLarge}>
        <Icon size={60} name="WalleyLogo" />
      </XxlStack>
    </XxlStack>
  );
};
