import { QUERY_KEYS } from "@/react-app/constants";
import { useQuery } from "@tanstack/react-query";
import { isNotEmpty } from "@xxl/common-utils";
import { useApiClients } from "react-app/src/contexts/ApiClients";

export const useSizeGuidesQuery = (
  brandCode: string,
  categoryCodes: string[]
) => {
  const {
    nextFrontendApi: { getSizeGuide },
  } = useApiClients();

  const fetchSizeGuides = async () => {
    const response = await getSizeGuide(brandCode, categoryCodes);
    return response.data !== "" ? response.data : null;
  };

  return useQuery({
    enabled: isNotEmpty(brandCode) && isNotEmpty(categoryCodes),
    queryKey: [QUERY_KEYS.SIZE_GUIDES, { brandCode }, { categoryCodes }],
    queryFn: fetchSizeGuides,
  });
};
